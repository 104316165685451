<template>
  <div class="optimization-wrap">
    <div class="optimization-top">
      <div class="optimization-top-body">
        <texts
          class="optimization-top-title"
          :text="$t('trainedAi.optimization.settingConditions')"
          size="large"
        />
        <button @click="$emit('show-optimization-tutorial')">
          <text-with-icon
            class="expression-top-help"
            size="min"
            :text="$t('trainedAi.optimization.tutorial.button')"
            iconName="info"
            color="gray"
          />
        </button>
      </div>
      <div
        v-if="conditionsList && conditionsList.length > 0"
        class="optimization-inner"
      >
        <fuse-search
          class="optimization-search"
          :placeholder="$t('training.config.detail.searchColumns')"
          :option="option"
          gray
          :value="conditionsList"
          :searchTargetFunc="getColumnName"
          @input="search($event)"
        />
        <div class="optimization-target">
          <texts
            class="optimization-target-title"
            :text="$t('training.config.detail.optimizationColumn')"
            size="small"
            color="gray"
          />
          <div class="optimization-target-list">
            <texts
              v-for="column in optimizationColumns"
              :key="column"
              :text="column"
              class="optimization-target-item"
            />
          </div>
        </div>
      </div>
    </div>
    <optimization-list-item
      :value="serachResults ? serachResults : conditionsList"
      :describe="describe"
      :noSearchResults="noSearchResults"
      :validColumns="rawPredictionColumnOptions"
      :disableOptimization="disableOptimization"
      @input="updateValue"
    />
  </div>
</template>

<script>
import fuseSearch from '@/components/molecules/fuse-search.vue'
import textWithIcon from '@/components/molecules/text-with-icon'
import optimizationListItem from '@/components/organisms/optimization/optimization-list-item'

import { setValue, updateColumnValue } from '@/lib/optimization'

export default {
  components: {
    fuseSearch,
    textWithIcon,
    optimizationListItem
  },
  data() {
    return {
      conditionsList: [],
      serachResults: null,
      noSearchResults: false,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'column',
            weight: 1
          }
        ],
        distance: 10000
      }
    }
  },
  props: {
    selectedTrainingData: Object,
    optimizationColumns: Array,
    rawPredictionColumnOptions: Object,
    disableOptimization: Array
  },
  methods: {
    search(event) {
      if (event.value.length !== 0) {
        this.serachResults = event.result
      } else {
        this.serachResults = this.conditionsList
      }
      if (event.noSearchResults) {
        this.noSearchResults = true
      } else {
        this.noSearchResults = false
      }
    },
    updateValue(e) {
      updateColumnValue(e, this.conditionsList)
      this.$emit('input', this.conditionsList)
    }
  },
  computed: {
    describe() {
      if (!this.selectedTrainingData) return []
      return this.selectedTrainingData.describe
    },
    getColumnName() {
      return (item) => item?.column
    }
  },
  mounted() {
    const res = setValue(
      this.selectedTrainingData.dtypes,
      this.optimizationColumns
    )
    this.conditionsList = res
  },
  watch: {
    conditionsList: {
      handler(newVal) {
        this.$emit('input', { value: newVal, type: 'optimization' })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.optimization {
  &-wrap {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-top {
    flex-shrink: 0;
    margin: 0 0 $space-sub;
    &-body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 $space-sub;
      &::v-deep {
        .c-text {
          margin-bottom: adjustVW(-1);
        }
      }
    }
  }
  &-inner {
    display: grid;
    grid-template-columns: adjustVW(286) 1fr;
    grid-column-gap: $space-medium;
  }
  &-search {
    max-height: adjustVW(48);
    &::v-deep div {
      height: 100%;
    }
  }
  &-target {
    overflow: hidden;
    width: 100%;
    &-title {
      margin-bottom: $space-min;
    }
    &-list {
      overflow-x: auto;
      display: flex;
      grid-column-gap: $space-small;
      padding-bottom: $space-text;
      @include scrollbar;
    }
    &-item {
      flex-shrink: 0;
      max-width: adjustVW(160);
    }
  }
}
</style>
