<template>
  <div class="delete-column-wrap">
    <div class="delete-column-top">
      <InputBox
        v-model="prompt"
        class="prompt"
        isTextArea
        isGray
        size="description"
      />
      <ButtonMain
        class="generate-button"
        :text="$t('preprocessing.generateConditionFromText')"
        type="emphasis"
        @click="conditionFromPrompt"
      />
    </div>
    <div class="warning">
      <texts
        color="emphasis"
        :text="$t('preprocessing.generateConditionWarning')"
      />
    </div>
    <ConditionList
      :columns="columns"
      :settings="settings"
      @input="settings = $event"
      @do-delete-rows="$emit('do-delete-rows', settings)"
    />
  </div>
</template>

<script>
import InputBox from '@/components/molecules/input-box'
import ButtonMain from '@/components/atoms/button-main'
import ConditionList from './delete-rows/condition-list.vue'
import axios from 'axios'

export default {
  components: {
    InputBox,
    ButtonMain,
    ConditionList
  },
  data() {
    return {
      prompt: '',
      settings: {
        connector: 'or',
        conditions: []
      }
    }
  },
  props: {
    columns: Array
  },
  methods: {
    async conditionFromPrompt() {
      const res = await axios.post('/api/mf/preproc/delete_instructions', {
        prompt: this.prompt,
        columns: this.columns
      })
      this.settings = res.data
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.delete-column-wrap {
  overflow-y: scroll;
  height: 100%;
  .delete-column-top {
    display: flex;
  }
  .prompt {
    width: 80%;
    height: 30%;
    margin: $space-base;
    resize: none;
  }
  .warning {
    margin: $space-base;
  }
  .generate-button {
    height: $space-large;
    margin: $space-base;
  }
}
</style>
