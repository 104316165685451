<template>
  <div class="optimization">
    <card-base class="optimization-card" :data-tutorial-optimization="0">
      <div class="optimization-top">
        <div class="optimization-top-title">
          <texts
            :text="$t('trainedAi.optimization.settingConditions')"
            size="large"
          />
          <button @click="$emit('show-optimization-tutorial')">
            <text-with-icon
              class="optimization-top-help"
              iconName="info"
              :text="$t('trainedAi.optimization.tutorial.button')"
              size="min"
              color="gray"
            />
          </button>
        </div>
        <div
          v-if="
            optimizationConditions.columnConditions &&
              optimizationConditions.columnConditions.length > 0
          "
          class="optimization-inner"
        >
          <fuse-search
            class="optimization-search"
            :placeholder="$t('training.config.detail.searchColumns')"
            :option="option"
            :searchTargetFunc="getColumnName"
            gray
            :value="optimizationConditions.columnConditions"
            @input="search($event)"
          />
          <div class="optimization-target">
            <texts
              class="optimization-target-title"
              :text="$t('training.config.detail.optimizationColumn')"
              size="small"
              color="gray"
            />
            <div class="optimization-target-list">
              <texts
                v-for="column in trainedAi.optimizationColumns"
                :key="column"
                :text="column"
                class="optimization-target-item"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="optimization-list-wrap">
        <optimization-list-item
          class="optimization-list"
          :value="
            serachResults
              ? serachResults
              : optimizationConditions.columnConditions
          "
          :describe="describe"
          :noSearchResults="noSearchResults"
          :validColumns="optimizationInfo.validPredictionColumns"
          isInference
          :disableOptimization="disableOptimization"
          @input="updateValue"
        />
        <button class="optimization-history" @click="showPopup">
          <text-with-icon
            iconName="save"
            :text="$t('trainedAi.optimization.history.useHistory')"
            size="small"
            color="emphasis"
          />
        </button>
      </div>
    </card-base>
    <card-base class="optimization-card" :data-tutorial-optimization="3">
      <optimization-expression-desc />
      <optimization-expression-list
        class="optimization-card-list"
        :value="optimizationConditions.expressions"
        :columns="columns"
        :validExpressions="optimizationInfo.validExpressions"
        :validColumns="optimizationInfo.validPredictionColumns"
        isInference
        @input="updateExpressionValue"
        @check-expressions="$emit('check-expressions', $event)"
      />
    </card-base>
    <popup
      :showPopup="show"
      :title="$t('trainedAi.optimization.history.title')"
      maxHeight
      @close-modal="closePopup()"
    >
      <texts
        class="popup-desc"
        :text="$t('trainedAi.optimization.history.desc')"
        color="gray"
      />
      <div class="popup-inner">
        <transition-toggle-contents>
          <template v-if="!optimizationInfo.loadOptimizationConditionsList">
            <list-item-slot
              class="popup-list"
              :field="field"
              :items="optimizationConditionsList"
            >
              <template #name="entry">
                <div class="popup-item-summary">
                  <texts class="popup-item-name" :text="entry.entry.name" />
                  <description
                    class="popup-item-desc"
                    :text="entry.entry.description"
                    isGray
                  />
                  <button-main
                    class="popup-item-button"
                    :text="$t('trainedAi.optimization.history.button')"
                    type="emphasis"
                    size="thin"
                    fontSize="small"
                    @click="setConditions(entry.listIndex)"
                  />
                </div>
              </template>
              <template #conditions="entry">
                <div class="popup-item-detail">
                  <optimization-conditions-list
                    class="popup-item-conditions"
                    :optimizationConditions="entry.entry"
                    :limit="-1"
                  />
                </div>
              </template>
            </list-item-slot>
          </template>
          <loading v-else />
        </transition-toggle-contents>
      </div>
    </popup>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import cardBase from '@/components/atoms/card-base.vue'
import description from '@/components/atoms/description.vue'
import loading from '@/components/atoms/loading.vue'
import fuseSearch from '@/components/molecules/fuse-search.vue'
import popup from '@/components/molecules/popup/popup.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import listItemSlot from '@/components/molecules/list-item-slot.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import optimizationListItem from '@/components/organisms/optimization/optimization-list-item'
import optimizationExpressionList from '@/components/organisms/optimization/optimization-expression-list'
import optimizationExpressionDesc from '@/components/organisms/optimization/optimization-expression-desc'
import optimizationConditionsList from '@/components/organisms/optimization/optimization-conditions-list'

import {
  setValue,
  updateColumnValue,
  setByColumnConditions,
  setByExpressions
} from '@/lib/optimization'

export default {
  components: {
    buttonMain,
    cardBase,
    description,
    loading,
    fuseSearch,
    popup,
    textWithIcon,
    listItemSlot,
    transitionToggleContents,
    optimizationListItem,
    optimizationExpressionList,
    optimizationExpressionDesc,
    optimizationConditionsList
  },
  data() {
    return {
      show: false,
      optimizationConditions: {
        columnConditions: [],
        expressions: [],
        optimizationColumns: this.trainedAi.optimizationColumns
      },
      trainConfig: {
        learning_rate: 0.0001,
        batch_size: 64,
        epoch: 5,
        data: {
          ratio: 0.1
        },
        predictionColumn: [],
        inputColumns: this.trainedAi.optimizationColumns,
        saver: {
          evaluate_every: {
            train: 10,
            test: 30
          },
          num_checkpoints: 5
        }
      },
      columnConditionsSet: [],
      expressionsSet: [],
      serachResults: null,
      noSearchResults: false,
      option: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'column',
            weight: 1
          }
        ],
        distance: 10000
      },
      waitSetValue: false
    }
  },
  props: {
    trainedAi: Object,
    optimizationInfo: Object,
    optimizationConditionsList: Array,
    disableOptimization: Array
  },
  computed: {
    describe() {
      if (
        !this.optimizationInfo?.selectedTrainedAiDataset ||
        Object.keys(this.optimizationInfo.selectedTrainedAiDataset).length === 0
      )
        return []
      return this.optimizationInfo.selectedTrainedAiDataset.describe
    },
    columns() {
      if (!this.trainedAi) return []
      const target = this.trainedAi.datasets[0].columns
      return target.filter((column) => {
        return !this.trainedAi.optimizationColumns.includes(column)
      })
    },
    field() {
      return [
        {
          index: 0,
          key: 'name',
          label: this.$t('trainedAi.optimization.result.list.name'),
          width: 320
        },
        {
          index: 1,
          key: 'conditions',
          label: this.$t('trainedAi.optimization.conditions.titleResult'),
          width: 1180
        }
      ]
    },
    getColumnName() {
      return (item) => item?.column
    }
  },
  methods: {
    search(event) {
      if (event.value.length !== 0) {
        this.serachResults = event.result
      } else {
        this.serachResults = this.optimizationConditions.columnConditions
      }
      if (event.noSearchResults) {
        this.noSearchResults = true
      } else {
        this.noSearchResults = false
      }
    },
    showPopup() {
      this.show = true
      this.$emit('get-optimization-conditions-list')
    },
    closePopup() {
      this.show = false
    },
    updateValue(e) {
      updateColumnValue(e, this.optimizationConditions.columnConditions)
    },
    updateExpressionValue(e) {
      this.optimizationConditions.expressions = e
    },
    setConditions(index) {
      const target = this.optimizationConditionsList[index].conditions
      target.columnConditions.forEach((item) => {
        if (item?.column && this.columns.includes(item.column)) {
          if (!item.condition?.type) return
          const index = this.optimizationConditions.columnConditions.findIndex(
            (target) => {
              return target.column === item.column
            }
          )
          const setTarget = this.optimizationConditions.columnConditions[index]
          setTarget.condition = {
            ...item.condition
          }
          setTarget.integer = item.integer
          setTarget.disabledInteger = false
        }
      })
      target.expressions.forEach((item) => {
        this.optimizationConditions.expressions.push(item)
      })
      this.$nextTick(() => {
        this.closePopup()
      })
    },
    setPredictionColumns() {
      const set = new Set([...this.columnConditionsSet, ...this.expressionsSet])
      this.trainConfig.predictionColumn = Array.from(set)
      const config = {
        trainConfig: this.trainConfig,
        optimizationConditions: this.optimizationConditions
      }
      this.$emit('set-optimization', config)
    }
  },
  mounted() {
    const res = setValue(
      this.trainedAi.datasets[0].dtypes,
      this.trainedAi.optimizationColumns
    )
    this.optimizationConditions.columnConditions = res
  },
  destroyed() {
    this.$emit('reset-optimization-settings')
  },
  watch: {
    'optimizationConditions.columnConditions': {
      handler(newVal) {
        this.columnConditionsSet = setByColumnConditions(
          newVal,
          this.trainedAi.optimizationColumns
        )

        this.setPredictionColumns()
      },
      deep: true
    },
    'optimizationConditions.expressions': {
      handler(newVal) {
        this.expressionsSet = setByExpressions(
          newVal,
          this.trainedAi.optimizationColumns,
          this.trainedAi.datasets[0].dtypes
        )

        this.setPredictionColumns()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.optimization {
  display: grid;
  grid-template-columns: adjustVW(828) 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: $space-medium;
  height: 100%;
  &-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-list {
      overflow: hidden;
    }
  }
  &-top {
    flex-shrink: 0;
    margin: 0 0 $space-sub;
    &-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 $space-sub;
      &-inner {
        display: flex;
        grid-column-gap: $space-small;
      }
    }
    &-help {
      width: fit-content;
      &::v-deep .c-text {
        margin-bottom: adjustVW(-1);
      }
    }
  }
  &-inner {
    display: grid;
    grid-template-columns: adjustVW(286) 1fr;
    grid-column-gap: $space-medium;
  }
  &-search {
    max-height: adjustVW(48);
    &::v-deep div {
      height: 100%;
    }
  }
  &-list {
    overflow-x: hidden;
    height: 100%;
    &-wrap {
      position: relative;
      overflow: hidden;
    }
  }
  &-history {
    position: absolute;
    top: $space-sub;
    right: adjustVW(20);
    z-index: 100;
  }
  &-target {
    overflow: hidden;
    width: 100%;
    &-title {
      margin-bottom: $space-min;
    }
    &-list {
      overflow-x: auto;
      display: flex;
      grid-column-gap: $space-small;
      padding-bottom: $space-text;
      @include scrollbar;
    }
    &-item {
      flex-shrink: 0;
      max-width: adjustVW(160);
    }
  }
}

.popup {
  &-desc {
    flex-shrink: 0;
    margin-bottom: $space-small;
  }
  &-list {
    overflow-y: auto;
    padding-right: $space-base;
    @include scrollbar;
    &::v-deep {
      .list-item-entry-inner-last {
        align-self: flex-start;
      }
      .list-item-entry-block:hover {
        background: #fff;
      }
    }
  }
  &-item {
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
    &-summary {
      margin-right: $space-medium;
    }
    &-name {
      margin-bottom: adjustVW(2);
    }
    &-desc {
      margin-bottom: $space-sub;
    }
    &-button {
      height: adjustVW(36);
    }
    &-detail {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      &-title {
        margin-bottom: $space-base;
      }
    }
    &-conditions {
      overflow-y: auto;
      max-height: adjustVW(112);
      @include scrollbar;
    }
  }
  &-inner {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: adjustVW(960);
    height: 100%;
  }
}
</style>
